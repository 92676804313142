import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";
import OrderApprove from "../manage/OrderApprove";
import OrderCancel from "../manage/OrderCancel";
import OrderInvoice from "../manage/OrderInvoice";
import Delivery from "../manage/Delivery";
import Acceptance from "../manage/Acceptance";
import RegisterPayment from "../manage/RegisterPayment";

export const OrderStatus = ({ status }) => {
  switch (status) {
    case "COMPLETED":
      return <span className="badge badge-success">Completed</span>;
    case "PAYMENT_RECEIVED":
      return <span className="badge badge-success">Payment received</span>;
    case "WAITING_FOR_PAYMENT":
      return <span className="badge badge-warning">Waiting for payment</span>;
    case "WAITING_FOR_COD_APPROVAL":
      return <span className="badge badge-warning">Not Approved</span>;
    case "APPROVED":
      return <span className="badge badge-primary">Approved</span>;
    case "CANCELLED":
      return <span className="badge badge-danger">Cancelled</span>;
    case "PROCESSING":
      return <span className="badge badge-secondary">Processing</span>;
    default:
      return <span className="badge">{status}</span>;
  }
};

const OrderRow = ({ order: o, index }) => (
  <tr key={o.id}>
    <td>{index}</td>
    <td>{o.id}</td>
    <td style={{ lineHeight: 1 }}>
      {o.name} {o.sirname}
      <br />
      <small>{o.email}</small>
    </td>
    <td>{o.phone}</td>
    <td>{!!o.payment && (
      o.payment instanceof Object ? Object.keys(o.payment).map((key) => key+'\n') : o.payment.replaceAll("_", " ")
    )}</td>
    <td>{o.total}</td>
    <td>
      {o.reserved_at ? (
        <FontAwesomeIcon icon={faCheck} className="text-primary" />
      ) : (
        <FontAwesomeIcon icon={faTimes} className="text-secondary" />
      )}
    </td>
    <td>{o.cashier}</td>
    <td>
      <OrderStatus status={o.status} />
    </td>
    <td>{o.created_at}</td>
    <td>
      <div className="btn-group btn-group-sm">
        <Link className="btn btn-primary btn-sm" to={`/orders/${o.id}`}>
          Details
        </Link>
        <RegisterPayment order={o} />
        <OrderCancel order={o} />
      </div>
    </td>
    <td>
      <div className="btn-group btn-group-sm">
        <OrderInvoice order={o} />
        <Delivery order={o} />
        <Acceptance order={o} />
      </div>
    </td>
  </tr>
);

export default OrderRow;
