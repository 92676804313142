import React from "react";
import { connect } from "react-redux";
import { format_money } from "../helpers";
import Modal from "./Modal";
import { OrderStatus } from "./OrderRow";
import PaymentURL from "./PaymentURL";

const SingleOrder = ({ onClose, order: o, payments = [] }) => {
  return (
    <Modal id="single-order" onClose={onClose} open>
      {o ? (
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h3>Order info</h3>
              <dl className="row">
                <dt className="col-3">Total</dt>
                <dd className="col-9 h4">{o.total}</dd>
                <dt className="col-3">ID</dt>
                <dd className="col-9">{o.id}</dd>
                <dt className="col-3">Customer</dt>
                <dd className="col-9">
                  {o.name} {o.sirname}
                </dd>
                <dt className="col-3">E-mail</dt>
                <dd className="col-9">{o.email}</dd>
                <dt className="col-3">Phone</dt>
                <dd className="col-9">{o.phone}</dd>
                <dt className="col-3">Delivery</dt>
                <dd className="col-9">{o.delivery_type}</dd>
                {!!o.delivery_rate && (
                  <>
                    <dt className="col-3">Delivery cost</dt>
                    <dd className="col-9">{o.delivery_rate}</dd>
                  </>
                )}
                {!!o.city && (
                  <>
                    <dt className="col-3">City</dt>
                    <dd className="col-9">{o.city}</dd>
                  </>
                )}
                {!!o.address && (
                  <>
                    <dt className="col-3">Address</dt>
                    <dd className="col-9">{o.address}</dd>
                  </>
                )}
                <dt className="col-3">Phone</dt>
                <dd className="col-9">{o.phone}</dd>
                <dt className="col-3">Paid by</dt>
                <dd className="col-9">{!!o.payment && (
                  o.payment instanceof Object ? Object.keys(o.payment).map((key) => key+'\n') : o.payment.replaceAll("_", " ")
                )}</dd>
                <dt className="col-3">Cashier</dt>
                <dd className="col-9">{o.cashier}</dd>
                <dt className="col-3">Status</dt>
                <dd className="col-9">
                  <OrderStatus status={o.status} />
                </dd>
                <dt className="col-3">Date</dt>
                <dd className="col-9">{o.created_at}</dd>
                <dt className="col-3">Comment</dt>
                <dd className="col-9">{o.note}</dd>
              </dl>
            </div>
            <div className="col-md-4">
              {![
                "COMPLETED",
                "PAYMENT_RECEIVED",
                "WAITING_FOR_INSTALLMENT",
              ].includes(o.status) &&
                payments.map((p) => (
                  <PaymentURL key={p} payment={p} order={o} />
                ))}
            </div>
          </div>
          <h3>Inside order</h3>
          <table className="table table-bordered ">
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th style={{ minWidth: 120 }}>Subtotal</th>
            </tr>
            {o.products.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.model}</td>
                <td style={{ fontSize: "80%" }}>{product.name}</td>
                <td>{product.quantity}</td>
                <td>{product.price}</td>
                <td>{format_money(product.price * product.quantity)} GEL</td>
              </tr>
            ))}
            <tfoot>
              <tr>
                <td colSpan="5">
                  <b>Total:</b>
                </td>
                <td>{format_money(o.subtotal)} GEL</td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : (
        <div className="text-center py-5">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  payments: state.payments,
});

export default connect(mapStateToProps)(SingleOrder);
