import {
  ORDER_RETRIEVED,
  ORDER_CREATED,
  ORDER_DISMISS_MESSAGE,
  ORDER_UPDATED,
} from "../actions";

export default (
  state = {
    items: {},
    success: false,
    meta: {},
  },
  { type, payload }
) => {
  switch (type) {
    case ORDER_RETRIEVED:
      const transformed = payload.data.reduce(function (cur, p) {
        return { ...cur, [p.id]: p };
      }, {});
      return {
        items: {
          ...transformed,
        },
        meta: payload.meta,
      };
    case ORDER_CREATED:
      return {
        items: { ...state.items, [payload.id]: payload },
        success: true,
      };
    case ORDER_UPDATED:
      const { [payload.id]: item } = state.items;
      return {
        ...state,
        items: { ...state.items, [payload.id]: { ...item, ...payload } },
      };

    case ORDER_DISMISS_MESSAGE: {
      return {
        ...state,
        success: false,
      };
    }
    default:
      return state;
  }
};

export const selectOrders = (state) => Object.values(state.order.items);
