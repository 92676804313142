import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { registerOfflinePayment, getOfflinePayments } from "../actions";
import Modal from "../components/Modal";

const RegisterPayment = ({ order, action, user, offline_payments }) => {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(["Cash"]);

  console.log(paymentInfo);
  
  if (
    !["WAITING_FOR_PAYMENT", "WAITING_FOR_COD_APPROVAL"].includes(order.status)
  ) {
    return <></>;
  }

  const submit = (e) => {
    e.preventDefault();
    setLoading();
    action({ payment_type: paymentInfo, order_id: order.id }).then(() =>
      setLoading(false)
    );
  };
  return (
    <>
      {showForm && (
        <Modal
          open={showForm}
          onClose={(e) => setShowForm(false)}
          id="RegisterPayment"
        >
          <h3>{order.total} ?</h3>
          <form onSubmit={submit} className="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Payment Received By</label>
                <input
                  class="form-control"
                  disabled
                  type="text"
                  value={user.name}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Offline payment type</label>
                <select
                  name="payment_type[]"
                  id="payment_type"
                  required
                  class="form-control"
                  onChange={(e) => setPaymentInfo(!paymentInfo.includes(e.target.value) 
                    ? [...paymentInfo, e.target.value] 
                    : paymentInfo.filter(item => item != e.target.value))}
                  value={paymentInfo}
                  multiple
                >
                  {offline_payments.map(($p) => (
                    <option value={$p}>{$p}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-center col-12 pt-4">
              <button
                type="button"
                disabled={loading}
                onClick={(e) => setShowForm(false)}
                className="btn btn-lg"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary btn-lg"
              >
                Register payment
              </button>
            </div>
          </form>
        </Modal>
      )}
      <button onClick={() => setShowForm(true)} className="btn btn-info">
        Register payment
      </button>
    </>
  );
};

export default connect((state) => ({ user: state.auth.user, offline_payments: state.offline_payments }), {
  action: registerOfflinePayment,
})(RegisterPayment);
