import React, { useState } from "react";
import api from "../api";

const OrderInvoice = ({ order }) => {
  const [disabledPDF, togglePDF] = useState(false);
  const [disabledXLS, toggleXLS] = useState(false);
  if (
    ![
      "PAYMENT_RECEIVED",
      "APPROVED",
      "COMPLETED",
      "PROCESSING",
      "WAITING_FOR_COD_APPROVAL",
    ].includes(order.status)
  ) {
    return <></>;
  }
  const handlerPDF = (e) => {
    e.preventDefault();
    togglePDF(true);
    api.orders.invoice(order.id).then(() => {
      togglePDF(false);
    });
  };
  const handlerEXCEL = (e) => {
    e.preventDefault();
    toggleXLS(true);
    api.orders.excel(order.id).then(() => {
      toggleXLS(false);
    });
  };
  return (
    <>
      <button
        onClick={handlerPDF}
        disabled={disabledPDF}
        className="btn btn-outline-danger"
      >
        PDF
      </button>
      <button
        onClick={handlerEXCEL}
        disabled={disabledXLS}
        className="btn btn-outline-success"
      >
        Excel
      </button>
    </>
  );
};

export default OrderInvoice;
