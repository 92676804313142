import React from "react";
import Modal from "./Modal";

const SingleProduct = ({ product, onClose }) => {
  return (
    <Modal
      open={!!product}
      onClose={onClose}
      className="modal-small"
      id="single-product"
    >
      <div className="product-card" style={{ border: "none" }}>
        <div className="row">
          <div className="col-6">
            <img
              src={product.image ? product.image.preview_url : ""}
              alt={product.name}
            />
          </div>
          <div className="col-6">
            <h2 className="product-card__name">{product.name}</h2>
            <div className="product-card__sku">{product.model}</div>
            <hr />
            <p style={{ fontSize: "80%" }}>{product.excerpt}</p>
            {product.price !== product.final_price ? (
              <div className="product-card__price">
                <b>Selling price: </b>
                <del>{product.price}</del>
                {product.final_price} GEL.
              </div>
            ) : (
              <div className="product-card__price">
                <b>Selling price: </b>
                {product.final_price || product.selling_price} GEL.
              </div>
            )}

            <div className="product-card__quantity">
              <b>Available Quantity: </b>
              {product.quantity} pcs.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SingleProduct;
