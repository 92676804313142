import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api";
import SingleOrder from "../components/SingleOrder";

const SingleOrderPage = () => {
  const { order_id } = useParams();
  const history = useHistory();
  const [order, updateOrder] = useState(null);
  useEffect(() => {
    api.shop.getOrder(order_id).then((res) => updateOrder(res.data));
  }, [order_id]);
  return <SingleOrder order={order} onClose={() => history.push("/orders")} />;
};

export default SingleOrderPage;
