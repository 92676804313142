import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import appReducer from "./reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import Axios from "axios";
import {
  cities,
  loggedIn,
  me,
  retrievePayments,
  retrieveShops,
  retrieveOfflinePayments,
  switchShop,
} from "./actions";

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const store = createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));

let currentValue = localStorage.getItem("_ginza_cashier_key");
let currentShop = localStorage.getItem("_ginza_cashier_shop");
store.subscribe(() => {
  const { auth, shop } = store.getState();
  if (currentValue !== auth.jwt.access_token) {
    Axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.jwt.access_token}`;
    currentValue = auth.jwt.access_token;
    localStorage.setItem("_ginza_cashier_key", currentValue);
    if (currentValue) {
      store.dispatch(retrieveShops());
      store.dispatch(retrievePayments());
      store.dispatch(retrieveOfflinePayments());
      store.dispatch(cities());
      store.dispatch(me());
    }
  }
  if (shop.current !== currentShop) {
    localStorage.setItem("_ginza_cashier_shop", shop.current);
  }
});
if (currentValue) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${currentValue}`;
  store.dispatch(loggedIn({ access_token: currentValue }));
  store.dispatch(me());
  store.dispatch(retrieveShops());
  store.dispatch(retrievePayments());
  store.dispatch(retrieveOfflinePayments());
  store.dispatch(cities());
}

if (currentShop) {
  store.dispatch(switchShop(currentShop * 1));
}

export default store;
