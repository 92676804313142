import React from "react";
import Cashier from "../components/Cashier";

const MainPage = () => (
  <main className="">
    <div className="container-fluid">
      <Cashier />
    </div>
  </main>
);

export default MainPage;
