import { faGlobe, faIdCard, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../components/Modal";
import { keyBy } from "lodash";
import { processApplication } from "../actions";
import ProductFinder from "../components/ProductFinder";
import ProductTable from "../components/ProductTable";
import SingleProduct from "../components/SingleProduct";
import { selectUser } from "../reducers/authReducer";
import { useHistory } from "react-router-dom";

const Application = ({
  application,
  payments,
  offline_payments,
  city,
  user,
  onClose,
  onSubmit,
}) => {
  const currentCity = city.filter((c) => c.is_default)[0] || city[0];
  const [data, setData] = useState({ products: {} });
  const [current, setCurrent] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [paymentPage, setPaymentPage] = useState(false);

  const setState = (obj) => setData((prev) => ({ ...prev, ...obj }));

  useEffect(() => {
    console.log("Updated");
    setData({
      preferred_payment: "Cash",
      delivery_type: "DELIVERY_PICKUP",
      order_source: "SHOP",
      city_id: currentCity && currentCity.id,
      ...application,
      products: keyBy(
        application.products
          ? application.products.map((p) => ({
              ...p,
              final_price: p.sale_price,
            }))
          : [],
        "id"
      ),
    });
  }, [application, currentCity]);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setState({ [name]: value });
  };
  const handleDec = ({ id }) => (e) => {
    e.preventDefault();
    setData((prev) => {
      const { [id]: product } = prev.products;
      const updatedP = { ...product, qty: product.qty - 1 };
      return { ...prev, products: { ...prev.products, [id]: updatedP } };
    });
  };

  const handleInc = ({ id }) => (e) => {
    e.preventDefault();
    setData((prev) => {
      const { [id]: product } = prev.products;
      const updatedP = { ...product, qty: product.qty + 1 };
      return { ...prev, products: { ...prev.products, [id]: updatedP } };
    });
  };

  const handleRemove = (id) => (e) => {
    e.preventDefault();
    setData((prev) => {
      const { [id]: _, ...products } = prev.products;
      return { ...prev, products: products };
    });
  };

  const handleAddProduct = (product) => {
    setData((prev) => {
      const { id: inCart } = prev.products;
      if (inCart) {
        return prev;
      }
      const newProduct = { ...product, qty: 1 };
      return {
        ...prev,
        products: { ...prev.products, [product.id]: newProduct },
      };
    });
  };

  const validate = (params) => {
    if (!params.name) {
      return false;
    }
    if (!params.phone) {
      return false;
    }
    if (!params.order_source) {
      return false;
    }
    if (!params.city_id) {
      return false;
    }

    return true;
  };

  const handler = (register_payment = false) => {
    const { products, ...rest } = data;
    console.log(rest);
    const requestBody = {
      ...rest,
      register_payment,
    };
    requestBody.products = Object.values(products).map((p) => ({
      id: p.id,
      quantity: p.qty,
    }));
    if (!validate(requestBody)) {
      alert("Not enough data");
      return;
    }
    if (paymentPage || !["Cash", "UzCard", "Humo"].includes(data.preferred_payment)) {
      setLoading(true);
      onSubmit(requestBody)
        .then((data) => {
          setLoading(false);
          setPaymentPage(false);
          history.push(`/orders/${data.order_id}`);
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            alert(error.response.data.error_note);
          } else {
            // Something happened in setting up the request and triggered an Error
            alert("Error", error.message);
          }
          setLoading(false);
          setPaymentPage(false);
        });
    } else {
      setPaymentPage(true);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    handler();
  };

  return (
    <Modal open onClose={onClose} className="modal-lg" id="oneClickProcessor">
      <form onSubmit={submit}>
        {paymentPage ? (
          <>
            <h3 className="text-center">Did you received payment?</h3>
            <div className="text-center">
              <button type="submit" className="btn btn-default">
                NO
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handler(true);
                }}
                className="btn btn-primary"
              >
                Yes
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="card mb-1">
                  <div className="card-header">
                    <h3>User info</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label for="Name">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={data.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label for="Name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        required
                        value={data.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label for="sirname">Surname</label>
                      <input
                        type="text"
                        className="form-control"
                        name="surname"
                        value={data.surname}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label for="phone">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={data.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-9">
                <div className="card mb-1">
                  <div className="card-header">
                    <h3>Delivery and Payment</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label for="orderSource">Order Source</label>
                          <select
                            name="order_source"
                            value={data.order_source}
                            id="orderSource"
                            className="form-control"
                            onChange={handleInputChange}
                          >
                            <option value="ORDER_TELEGRAM">Telegram</option>
                            <option value="ORDER_INSTAGRAM">Instagram</option>
                            <option value="ORDER_FACEBOOK">Facebook</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label for="deliveryType">Delivery Type</label>
                          <select
                            name="delivery_type"
                            value={data.delivery_type}
                            id="deliveryType"
                            className="form-control"
                            onChange={handleInputChange}
                          >
                            <option value="DELIVERY_STANDART">Standart</option>
                            <option value="DELIVERY_PICKUP">Pickup</option>
                          </select>
                        </div>
                      </div>
                      {data.delivery_type === "DELIVERY_STANDART" && (
                        <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="city">
                                City
                                {data.delivery_type === "DELIVERY_STANDART" && (
                                  <sup style={{ color: "red" }}>*</sup>
                                )}
                              </label>
                              <select
                                required={
                                  data.delivery_type === "DELIVERY_STANDART"
                                }
                                name="city_id"
                                id="city"
                                className="form-control"
                                onChange={handleInputChange}
                                value={data.city_id}
                              >
                                {city.map((c) => (
                                  <option value={c.id} key={c.id}>
                                    {c.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="form-group">
                              <label htmlFor="address">
                                Address
                                {data.delivery_type === "DELIVERY_STANDART" && (
                                  <sup style={{ color: "red" }}>*</sup>
                                )}
                              </label>
                              <input
                                required={
                                  data.deliveryType === "DELIVERY_STANDART"
                                }
                                type="text"
                                className="form-control"
                                value={data.address}
                                name="address"
                                onChange={handleInputChange}
                                placeholder="Address here ...."
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card mb-1">
                  <div className="card-header">
                    <fieldset className="row py-3 align-items-stretch">
                      <div className="col-6 col-md-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setState({ preferred_payment: "Cash" });
                          }}
                          className={`btn btn-block ${
                            data.preferred_payment === "Cash"
                              ? "btn-primary"
                              : "btn-outline-secondary"
                          }`}
                          value="Cash"
                          style={{ height: "100%" }}
                          name="payment"
                        >
                          <FontAwesomeIcon icon={faWallet} size="3x" />
                          <div className="h5">Cash</div>
                        </button>
                      </div>
                      {payments.map((p) => (
                        <div className="col-6 col-md-3" key={p}>
                          <button
                            style={{ height: "100%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setState({ preferred_payment: p });
                            }}
                            className={`btn btn-block ${
                              data.preferred_payment === p
                                ? "btn-primary"
                                : "btn-outline-secondary"
                            }`}
                            value={p}
                            name="payment"
                          >
                            <FontAwesomeIcon icon={faGlobe} size="3x" />
                            <div className="h5">Online {p}</div>
                          </button>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h3 className="mb-0">Add products to the order</h3>
                  </div>
                  <div className="col-md-6" style={{ marginBottom: -15 }}>
                    <ProductFinder
                      afterFound={handleAddProduct}
                      customer_id={application.customer_id}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ProductTable
                  items={Object.values(data.products)}
                  inc={handleInc}
                  dec={handleDec}
                  remove={handleRemove}
                  onProductClick={(productID) => (e) => {
                    e.preventDefault();
                    const p = data.products[productID];
                    console.log({ p });
                    p && setCurrent(p);
                  }}
                />
              </div>
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={loading}
                >
                  Register
                </button>
              </div>
            </div>
          </>
        )}
      </form>
      {!!current && (
        <SingleProduct
          product={current}
          onClose={() => {
            setCurrent(null);
          }}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  payments: state.payments,
  city: state.city,
});

export default connect(mapStateToProps, { onSubmit: processApplication })(
  Application
);
