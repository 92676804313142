import React, { useEffect } from "react";
import { connect } from "react-redux";
import { dismissOrderCreated } from "../actions";
import "./notification.scss";

const Notification = ({ show = false, dismiss }) => {
  return (
    show && (
      <div className="notification">
        <div className="alert alert-primary">
          <h4>Order registered</h4>
          <p>Order was registed and sync with backend</p>
          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => {
              e.preventDefault();
              dismiss();
            }}
          >
            Dismiss
          </button>
        </div>
      </div>
    )
  );
};

export default connect((state) => ({ show: state.order.success }), {
  dismiss: dismissOrderCreated,
})(Notification);
