import React from "react";
import Modal from "./Modal";
import "./_product-card.scss";

const ProductSelector = ({ products = [], onSelect, close }) => (
  <Modal open={products.length} onClose={close} id="ProductSelector">
    <div className="product-card__row">
      {products.map((p) => (
        <button
          className="product-card"
          disabled={p.quantity === 0}
          onClick={(e) => {
            e.preventDefault();
            onSelect(p);
          }}
          type="button"
          key={p.id}
        >
          <img src={p.image ? p.image.preview_url : ""} alt={p.name} />
          <div className="product-card__name">{p.name}</div>
          <div className="product-card__price">
            <b>{p.final_price}</b>
          </div>
          {p.final_price !== p.price && (
            <div className="product-card__price">
              <del>{p.price}</del>
            </div>
          )}
          <div className="product-card__quantity">{p.quantity} pcs.</div>
        </button>
      ))}
    </div>
  </Modal>
);

export default ProductSelector;
