import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import api from "../api";
import { selectCurrentShop } from "../reducers/shopReducer";
import ProductSelector from "./ProductSelector";

const ProductFinder = ({
  afterFound = null,
  shop = {},
  customer_id = null,
}) => {
  const { search } = api.shop;
  const [{ products, key }, updateState] = useState({
    products: [],
    key: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    updateState({ key: e.target.value.trim(), products: [] });
  };

  const timeout = useRef(null);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [products.length]);

  useEffect(() => {
    if (key.length > 4) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        search(shop.id, key, customer_id).then(({ data }) => {
          if (data.length === 1) {
            return afterFound(data[0]);
          }
          if (data.length === 0) {
            console.log("No products found");
            return;
          }
          updateState({ products: data, key });
        });
      }, 400);
    }
  }, [key, customer_id, search, shop.id, afterFound]);

  const handleCloseField = () => {
    updateState({ key: "", products: [] });
  };

  const handleSelect = (product) => {
    afterFound(product);
    handleCloseField();
  };

  return (
    <>
      <ProductSelector
        products={products}
        onSelect={handleSelect}
        close={handleCloseField}
      />
      <div className="form-group">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Enter SKU, Barcode, Product name"
          onChange={handleChange}
          value={key}
          ref={inputRef}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  shop: selectCurrentShop(state) || {},
});

export default connect(mapStateToProps, {})(ProductFinder);
