import React from 'react';
import { connect } from 'react-redux';
import { switchShop } from '../actions';
import "./right-nav.scss"

const RightNav = ({shops,current,setCurrent})=>{
    return <div className="right-nav">
        {shops.map((shop)=>(
            <button className={`right-nav__button${current===shop.id? " right-nav__button--current":''}`} key={shop.id} 
                onClick={e=>{e.preventDefault();setCurrent(shop.id)}}
            >
                {shop.name}
            </button>
        ))}
    </div>
}

const mapStateToProps = ({shop})=>({current: shop.current,shops: shop.items})


export default connect(mapStateToProps,{
    setCurrent:switchShop
})(RightNav)