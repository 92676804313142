import React, { useRef } from "react";
import Portal from "./Portal";
import "./_modal.scss";

const Icon = () => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.15416 0.200196C0.890133 -0.0667321 0.462054 -0.0667321 0.198023 0.200196C-0.0660078 0.467125 -0.0660076 0.899902 0.198023 1.16683L5.04386 6.06585L0.328289 10.8332C0.0642585 11.1001 0.0642583 11.5329 0.328289 11.7998C0.59232 12.0667 1.0204 12.0667 1.28443 11.7998L6 7.03248L10.7156 11.7998C10.9796 12.0667 11.4077 12.0667 11.6717 11.7998C11.9357 11.5329 11.9357 11.1001 11.6717 10.8332L6.95614 6.06585L11.802 1.16683C12.066 0.899902 12.066 0.467125 11.802 0.200196C11.5379 -0.0667321 11.1099 -0.0667321 10.8458 0.200196L6 5.09921L1.15416 0.200196Z"
      fill="black"
    />
  </svg>
);

const Modal = ({
  onClose = null,
  children,
  open = false,
  className = "",
  id = null,
}) => {
  const dimmer = useRef(null);
  const strId = id
    ? id
    : Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);

  const handleClose = (e) => {
    if (e.target.isSameNode(dimmer.current)) {
      onClose();
    }
  };

  return (
    <Portal id={strId}>
      {open && (
        <div onClick={handleClose} className="modal__dimmer" ref={dimmer}>
          <div className={`modal ${className}`}>
            <button
              className="modal__close-btn"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <Icon /> <span>Close</span>
            </button>
            {children}
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Modal;
