import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { isAuthenticated } from '../reducers/authReducer';


const GuestRoute = ({component:Component,isAuthenticated,...rest})=> (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );



export default connect(state=>({isAuthenticated: isAuthenticated(state)}))(GuestRoute)

