import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

export const OrderStatus = ({ status }) => {
  switch (status) {
    case "COMPLETED":
      return <span className="badge badge-success">Completed</span>;
    case "PAYMENT_RECEIVED":
      return <span className="badge badge-success">Payment received</span>;
    case "WAITING_FOR_PAYMENT":
      return <span className="badge badge-warning">Waiting for payment</span>;
    case "WAITING_FOR_COD_APPROVAL":
      return <span className="badge badge-warning">Not Approved</span>;
    case "APPROVED":
      return <span className="badge badge-primary">Approved</span>;
    case "CANCELLED":
      return <span className="badge badge-danger">Cancelled</span>;
    case "PROCESSING":
      return <span className="badge badge-secondary">Processing</span>;
    default:
      return <span className="badge">{status}</span>;
  }
};

const ApplicationRow = ({ application: o, index }) => {
  let className = "";
  if (!!o.processed_at) {
    className = "table-success";
  }
  if (!!o.cancelled_at || (o.order && o.order.status === "CANCELLED")) {
    className = "table-danger";
  }
  const Label = o.order ? <OrderStatus status={o.order.status} /> : <></>;
  return (
    <tr key={o.id} className={className}>
      <td>{index}</td>
      <td>{o.id}</td>
      <td style={{ lineHeight: 1 }}>{o.name}</td>
      <td>{o.phone}</td>
      <td>{o.cashier}</td>
      <td>{o.total}</td>
      <td>
        {!!o.processed_at && !o.cancelled_at && (
          <FontAwesomeIcon icon={faCheck} className="text-primary" />
        )}
        {!!o.cancelled_at && (
          <FontAwesomeIcon icon={faTimes} className="text-secondary" />
        )}
        {Label}
      </td>
      <td>{o.created_at}</td>
      <td>
        <div className="btn-group btn-group-sm">
          <Link
            className={`btn ${o.order ? "btn-info" : "btn-primary"} btn-sm`}
            to={o.order ? `/orders/${o.order.id}` : `/one-click/${o.id}`}
          >
            {o.order ? `Go to order` : `Process`}
          </Link>
        </div>
      </td>
    </tr>
  );
};
export default ApplicationRow;
