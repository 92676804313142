import React, { useState } from "react";
import { connect } from "react-redux";
import { cancelOrder } from "../actions";

const OrderCancel = ({ order, action }) => {
  const [disabled, toggle] = useState(false);
  if (
    ![
      "WAITING_FOR_COD_APPROVAL",
      "APPROVED",
      "WAITING_FOR_PAYMENT",
      "CREATED",
      "PROCESSING",
      "WAITING_FOR_INSTALLMENT",
    ].includes(order.status)
  ) {
    return <></>;
  }
  const handler = (e) => {
    e.preventDefault();
    toggle(true);
    let cc = window.confirm(`Do you really want to CANCEL order ${order.id}`);
    if (cc) {
      action(order.id);
    } else {
      toggle(false);
    }
  };
  return (
    <button onClick={handler} disabled={disabled} className="btn btn-danger">
      Cancel
    </button>
  );
};

export default connect(null, { action: cancelOrder })(OrderCancel);
