import Axios from "axios";

let instance = null;

const { REACT_APP_API_BASE_URL } = process.env;

const createInstance = () => {
  if (!instance) {
    if (REACT_APP_API_BASE_URL) {
      Axios.defaults.baseURL = REACT_APP_API_BASE_URL;
    }
    instance = {
      auth: {
        login: ({ email, password }) =>
          Axios.post("/login", { email, password }).then((res) => res.data),
        me: () => Axios.post("/me").then((res) => res.data),
      },
      shop: {
        all: () => Axios.get("/v1/stores").then((res) => res.data),
        search: (shop_id, key, customer_id) =>
          Axios.post("/v1/search", { shop_id, key, customer_id }).then(
            (res) => res.data
          ),
        createOrder: (data) =>
          Axios.post("/v1/order", data).then((res) => res.data),
        getOrder: (order_id) =>
          Axios.get(`/v1/orders/${order_id}`).then((res) => res.data),

        customers: (shop_id, page = 1) =>
          Axios.get("/v1/customers").then((res) => res.data),
      },
      orders: {
        approve: (id) =>
          Axios.post(`/v1/order/${id}/approve`).then((res) => res.data),
        registerPayment: (params) =>
          Axios.post(`/v1/order/register-payment`, params).then(
            (res) => res.data
          ),
        cancel: (id) =>
          Axios.post(`/v1/order/${id}/cancel`).then((res) => res.data),
        get: (shop_id, page = 1) =>
          Axios.get("/v1/orders", { params: { shop_id, page } }).then(
            (res) => res.data
          ),

        invoice: (id) =>
          Axios.post(`/order/${id}/invoice`, null, {
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `invoice_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            return true;
          }),
        excel: (id) =>
          Axios.post(`/order/${id}/excel`, null, {
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `excel_invoice_${id}.xls`);
            document.body.appendChild(link);
            link.click();
            return true;
          }),
        delivery: (id) =>
          Axios.post(`/order/${id}/deliverySticker`, null, {
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `sticker_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            return true;
          }),
        acceptance: (id) =>
          Axios.post(`/order/${id}/acceptance`, null, {
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `acceptance_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            return true;
          }),
      },
      application: {
        get: (shop_id, page = 1) =>
          Axios.get("/v1/applications", { params: { shop_id, page } }).then(
            (res) => res.data
          ),
        find: (id) =>
          Axios.get(`/v1/applications/${id}`).then((res) => res.data),
        process: (data) =>
          Axios.post(`/v1/applications/${data.id}`, data).then(
            (res) => res.data
          ),
      },
      city: {
        get: () => Axios.get("/v1/cities").then((res) => res.data),
      },
      payments: {
        all: () => Axios.get("/v1/payments").then((res) => res.data),
        offlinePayments: () => Axios.get('/v1/offline_payments').then((res) => res.data),
        getUrl: (order, system) =>
          Axios.post(`/v1/payments/${order}`, { system }).then(
            (res) => res.data
          ),
      },
    };
  }
  return instance;
};

export default createInstance();
