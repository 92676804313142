import React, { useState } from "react";
import api from "../api";

const PaymentURL = ({ order, payment }) => {
  const [url, setURL] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setLoading(true);
    api.payments.getUrl(order.id, payment).then((data) => {
      setLoading(false);
      setURL(data.url);
    });
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        className="btn btn-outline-primary btn-block"
      >
        {payment}
      </button>
      {loading && (
        <div className="text-center py-1">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {url && (
        <div className="text-center py-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            className="d-block text-center"
            style={{ wordBreak: "break-all", fontSize: 12 }}
          >
            {url}
          </a>
        </div>
      )}
    </>
  );
};

export default PaymentURL;
