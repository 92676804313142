import api from "./api";

export const PAYMENTS_RETRIEVED = "PAYMENTS_RETRIEVED";
export const OFFLINE_PAYMENTS_RETRIEVED = "OFFLINE_PAYMENTS_RETRIEVED";
export const CITY_RETRIEVED = "CITY_RETRIEVED";

export const PRODUCT_RETRIEVED = "PRODUCT_RETRIEVED";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_USER = "AUTH_USER";

export const SHOP_RETRIEVED = "SHOP_RETRIEVED";
export const SHOP_SWITCHED = "SHOP_SWITCHED";

export const ORDER_RETRIEVED = "ORDER_RETRIEVED";
export const ORDER_UPDATED = "ORDER_UPDATED";
export const ORDER_CREATED = "ORDER_CREATED";
export const ORDER_DISMISS_MESSAGE = "ORDER_DISMISS_MESSAGE";

export const ONE_CLICK_RETRIEVED = "ONE_CLICK_RETRIEVED";
export const ONE_CLICK_CREATED = "ONE_CLICK_CREATED";
export const ONE_CLICK_DISMISS_MESSAGE = "ONE_CLICK_DISMISS_MESSAGE";
export const ONE_CLICK_UPDATED = "ONE_CLICK_UPDATED";

export const cities = () => (dispatch) =>
  api.city.get().then((data) =>
    dispatch({
      type: CITY_RETRIEVED,
      payload: data,
    })
  );

export const loggedIn = (data) => ({
  type: AUTH_LOGIN,
  payload: data,
});

export const login = (credentials) => (dispatch) =>
  api.auth.login(credentials).then((data) => dispatch(loggedIn(data)));

export const logout = () => ({ type: AUTH_LOGOUT });
export const me = () => (dispatch) =>
  api.auth
    .me()
    .then((data) =>
      dispatch({
        type: AUTH_USER,
        payload: data,
      })
    )
    .catch((e) => {
      dispatch({ type: AUTH_LOGOUT });
    });

export const retrieveShops = () => (dispatch) =>
  api.shop.all().then((respBody) =>
    dispatch({
      type: SHOP_RETRIEVED,
      payload: respBody.data,
    })
  );

export const switchShop = (shop_id) => ({
  type: SHOP_SWITCHED,
  payload: { id: shop_id },
});

export const dismissOrderCreated = () => ({
  type: ORDER_DISMISS_MESSAGE,
});

export const retrievePayments = () => (dispatch) =>
  api.payments.all().then((data) =>
    dispatch({
      type: PAYMENTS_RETRIEVED,
      payload: data.payments,
    })
  );

export const retrieveOfflinePayments = () => (dispatch) =>
  api.payments.offlinePayments().then((data) =>
    dispatch({
      type: OFFLINE_PAYMENTS_RETRIEVED,
      payload: data.payments,
    })
  )

export const createOrder = (params) => (dispatch) =>
  api.shop.createOrder(params).then(({ data }) => {
    dispatch({
      type: ORDER_CREATED,
      payload: data,
    });
  });

export const getOrders = (shop_id, page) => (dispatch) =>
  api.orders.get(shop_id, page).then((data) =>
    dispatch({
      type: ORDER_RETRIEVED,
      payload: data,
    })
  );

export const approveOrder = (id) => (dispatch) =>
  api.orders.approve(id).then(({ data }) => {
    dispatch({
      type: ORDER_UPDATED,
      payload: data,
    });
  });

export const cancelOrder = (id) => (dispatch) =>
  api.orders.cancel(id).then(({ data }) => {
    dispatch({
      type: ORDER_UPDATED,
      payload: data,
    });
  });

export const registerOfflinePayment = ({ order_id, payment_type }) => (
  dispatch
) =>
  api.orders.registerPayment({ order_id, payment_type }).then(({ data }) => {
    dispatch({
      type: ORDER_UPDATED,
      payload: data,
    });
  });

export const getApplications = (shop_id, page) => (dispatch) =>
  api.application.get(shop_id, page).then((data) =>
    dispatch({
      type: ONE_CLICK_RETRIEVED,
      payload: data,
    })
  );

export const processApplication = (params) => (dispatch) =>
  api.application.process(params).then(({ data }) => {
    dispatch({
      type: ORDER_CREATED,
      payload: data,
    });
    return data;
  });
