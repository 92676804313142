import { combineReducers } from "redux";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import productReducer from "./productReducer";
import shopReducer from "./shopReducer";
import paymentReducer from "./paymentReducer";
import offlinePayments from "./offlinePaymentReducer";
import cityReducer from "./cityReducer";
import oneClickReducer from "./oneClickReducer";

export default combineReducers({
  product: productReducer,
  auth: authReducer,
  shop: shopReducer,
  order: orderReducer,
  payments: paymentReducer,
  offline_payments: offlinePayments,
  city: cityReducer,
  oneClick: oneClickReducer,
});
