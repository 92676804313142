import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import api from "../api";
import Application from "../oneclick/Application";

const OneClickCheckout = () => {
  const { application_id } = useParams();
  const history = useHistory();
  const [application, set] = useState({});
  useEffect(() => {
    api.application.find(application_id).then((res) => set(res.data));
  }, [application_id]);
  console.log(application);
  return (
    <Application
      application={application}
      onClose={() => history.push("/one-click")}
    />
  );
};

export default OneClickCheckout;
