import React, { useState } from "react";
import { connect } from "react-redux";
import { approveOrder } from "../actions";

const OrderApprove = ({ order, action }) => {
  const [disabled, toggle] = useState(false);
  if (!["PROCESSING", "WAITING_FOR_COD_APPROVAL"].includes(order.status)) {
    return <></>;
  }
  const handler = (e) => {
    e.preventDefault();
    toggle(true);
    let cc = window.confirm(`Confirm  order ${order.id}`);
    if (cc) {
      action(order.id);
    } else {
      toggle(false);
    }
  };
  return (
    <button onClick={handler} disabled={disabled} className="btn btn-info">
      Approve
    </button>
  );
};

export default connect(null, { action: approveOrder })(OrderApprove);
