import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login, me } from "../actions";

const LoginPage = ({ me, login, history }) => {
  const [state, updateState] = useState({
    data: {},
    error: null,
  });

  const setState = (data) => updateState({ ...state, ...data });

  const onChange = (e) => {
    setState({ data: { ...state.data, [e.target.name]: e.target.value } });
  };

  const onSubmit = (e) => {
    e.preventDefault(e);
    login(state.data)
      .then(() => me())
      .then(() => history.push("/"))
      .catch((error) => {
        if (error.response) {
          setState({ error: "Wrong Credentials" });
        } else {
          setState({ error: "Something went wrong" });
        }
      });
  };

  return (
    <main
      className="container-fluid"
      style={{ height: "100vh", backgroundColor: "#1b262c" }}
    >
      <div
        className="row"
        style={{
          color: "#fff",
          justifyContent: "center",
          alignItems: "center",
          height: "95%",
        }}
      >
        <div className="col-md-3">
          <h1>Login page</h1>
          {!!state.error && (
            <div className="alert alert-danger">{state.error}</div>
          )}
          <form onSubmit={onSubmit} autocomplete="chrome-off">
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input
                id="Email"
                type="email"
                name="email"
                className="form-control"
                value={state.data.email}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Password">Password</label>
              <input
                id="Password"
                type="password"
                name="password"
                className="form-control"
                value={state.data.password}
                onChange={onChange}
              />
            </div>
            <button className="btn btn-block btn-primary" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default withRouter(
  connect(null, {
    login,
    me,
  })(LoginPage)
);
