import { PRODUCT_RETRIEVED, PRODUCT_UPDATED } from "../actions";


export default (state = {},{type,payload})=>{
    switch(type){
        case PRODUCT_RETRIEVED:
            return {...state,...payload}
        case PRODUCT_UPDATED:
            const {[payload.id]: item} = state;
            return {...state, [payload.id]:{...item,...payload}}
        default : return state;
    }
}