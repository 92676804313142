import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "../reducers/authReducer";
import Topnav from "./Topnav";
import Notification from "../components/Notification";
import Nav from "../components/RightNav";

const AuthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <>
          <Nav />
          <Topnav />
          <Component {...props} /> <Notification />
        </>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default connect((state) => ({
  isAuthenticated: isAuthenticated(state),
}))(AuthRoute);
