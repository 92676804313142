import React from "react";
import { format_money } from "../helpers";

export default ({ items = [], onProductClick, dec, inc, remove }) => (
  <table className="table table-striped table-sm table-borderless">
    <thead>
      <tr>
        <th>№</th>
        <th>Product</th>
        <th>Price</th>
        <th>Sale Price</th>
        <th>Quantity</th>
        <th>Subtotal</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {!!items &&
        items.map((p, index) => (
          <tr key={p.id}>
            <td>{index + 1}</td>
            <td>
              <button
                onClick={onProductClick(p.id)}
                className="btn btn-link"
                style={{ padding: 0 }}
              >
                {p.name}
              </button>
            </td>
            <td>{format_money(p.price)}</td>
            <td>{format_money(p.final_price)}</td>
            <td className="btn-group btn-group-sm">
              <button className="btn btn-primary" onClick={dec(p)}>
                -
              </button>
              <div className="btn">{p.qty}</div>
              <button className="btn btn-primary" onClick={inc(p)}>
                +
              </button>
            </td>
            <td>{format_money(p.qty * p.final_price)}</td>
            <td>
              <button className="btn btn-danger btn-sm" onClick={remove(p.id)}>
                Remove
              </button>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);
