import { AUTH_LOGOUT, AUTH_LOGIN, AUTH_USER } from "../actions";

export default (state = { jwt: {}, user: {} }, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN:
      return { jwt: payload };
    case AUTH_LOGOUT:
      return { jwt: {}, user: {} };
    case AUTH_USER:
      return { ...state, user: payload };
    default:
      return state;
  }
};

export const isAuthenticated = (state) => !!state.auth.jwt.access_token;

export const selectUser = (state) => state.auth.user;
