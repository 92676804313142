import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faWallet, faGlobe } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { selectUser } from "../reducers/authReducer";
import { connect } from "react-redux";

const fields = [
  {
    id: "firstName",
    label: "First name",
    name: "name",
    type: "text",
    required: true,
    placeholder: "John",
  },
  {
    id: "lastName",
    label: "Last name",
    name: "sirname",
    type: "text",
    placeholder: "Doe",
  },
  {
    id: "phone",
    label: "Phone",
    name: "phone",
    type: "tel",
    required: true,
    placeholder: "+995596668484",
  },
  {
    id: "email",
    label: "E-mail",
    name: "email",
    type: "email",
    placeholder: "john.doe@example.ge",
  },
];
const DELIVERIES = ["DELIVERY_PICKUP", "DELIVERY_STANDART", "DELIVERY_EXPRESS"];
const ORDER_SOURCES = ["SHOP", "TELEGRAM", "INSTAGRAM", "FACEBOOK"];

const Checkout = ({ submit, open, close, user, payments, offline_payments, city }) => {
  const currentCity = city.filter((c) => c.is_default)[0] || city[0];
  const [data, setData] = useState({
    deliveryType: "PICKUP",
    preferred_payment: {
      "Cash": 0
    },
    payment_received: false,
    orderSource: "SHOP",
    promocode: ""
  });

  const [paymentPage, setPaymentPage] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const paymentIsSet = payments[0] !== "";

  const setState = (obj) => setData({ ...data, ...obj });

  const handleInputChange = (field) => (e) => {
    e.preventDefault();
    const { value } = e.target;
    console.log(e);
    setState({ [field]: value });
  };

  function paymentValue(e) {
    e.preventDefault();
    let obj = data.preferred_payment;
    obj[e.target.id] = e.target.value;
    setState({preferred_payment: obj});
  }

  function selectPayment(e) {
    e.preventDefault();
    
    let obj = data.preferred_payment;
    if (e.currentTarget.value in obj) {
      delete obj[e.currentTarget.value];
      setState({preferred_payment: obj});
    } else {
      setState({preferred_payment: {...data.preferred_payment, ...{ [e.currentTarget.value]: 0} } });
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (paymentPage || !["Cash", "UzCard", "Humo"].includes(data.preferred_payment)) {
      setLoading(true);
      submit({
        name: data.name,
        city_id: data.city_id || (currentCity && currentCity.id),
        sirname: data.sirname || "sirname",
        phone: data.phone,
        email: data.email || user.email,
        note: data.note,
        address: data.address,
        delivery_type: data.deliveryType,
        preferred_payment: data.preferred_payment,
        order_source: data.orderSource,
        promocode: data.promocode
      }).then(() => {
        setLoading(false);
        setPaymentPage(false);
      });
    } else {
      setPaymentPage(true);
    }
  };

  return (
    <Modal open={open} onClose={close} id="Checkout">
      <form className="container" onSubmit={handleFormSubmit}>
        {paymentPage ? (
          <>
            <h3 className="text-center">Did you received payment?</h3>
            <div className="text-center">
              <button type="submit" className="btn btn-default">
                NO
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  submit({
                    name: data.name,
                    city_id: data.city_id || (currentCity && currentCity.id),
                    sirname: data.sirname || "sirname",
                    phone: data.phone,
                    email: data.email || user.email,
                    note: data.note,
                    address: data.address,
                    delivery_type: data.deliveryType,
                    preferred_payment: data.preferred_payment,
                    order_source: data.orderSource,
                    promocode: data.promocode,
                    register_payment: true,
                  }).then(setLoading(false));
                }}
                className="btn btn-primary"
              >
                Yes
              </button>
            </div>
          </>
        ) : (
          <>
            <h3>Register new order</h3>
            <div className="row">
              {fields.map((f) => (
                <div className="col-md-6 col-lg-3" key={f.id}>
                  <div className="form-group">
                    <label htmlFor={f.id}>
                      {f.label}
                      {!!f.required && <sup style={{ color: "red" }}>*</sup>}
                    </label>
                    <input
                      required={f.required}
                      type={f.text}
                      className="form-control"
                      id={f.id}
                      value={data[f.name]}
                      name={f.name}
                      onChange={handleInputChange(f.name)}
                      placeholder={f.placeholder}
                    />
                  </div>
                </div>
              ))}
              <div className="col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="order_source">Order Source</label>
                  <select
                    name="orderSource"
                    id="order_source"
                    className="form-control"
                    onChange={handleInputChange("orderSource")}
                    value={data.orderSource}
                  >
                    {ORDER_SOURCES.map((t) => (
                      <option value={t} key={t}>
                        {t.replace("ORDER_", "")}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="order_source">Coupon</label>
                  <input
                      type="text"
                      className="form-control"
                      value={data.promocode}
                      name="promocode"
                      onChange={handleInputChange("promocode")}
                      placeholder="Coupon here ...."
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="note">Comment</label>
                  <textarea
                    name="note"
                    className="form-control"
                    rows="2"
                    placeholder="Place your comment here"
                    value={data.note}
                    onChange={handleInputChange("note")}
                  />
                </div>
              </div>
            </div>
            <h4>Delivery</h4>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="delivery_type">Delivery Type</label>
                  <select
                    name="deliveryType"
                    id="delivery_type"
                    className="form-control"
                    onChange={handleInputChange("deliveryType")}
                    value={data.deliveryType}
                  >
                    {DELIVERIES.map((t) => (
                      <option value={t} key={t}>
                        {t.replace("DELIVERY_", "")}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {data.deliveryType !== "PICKUP" && (
                <div class="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="city">
                        City
                        {data.deliveryType === "DELIVERY_STANDART" && (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </label>
                      <select
                        required={data.deliveryType === "DELIVERY_STANDART"}
                        name="city_id"
                        id="city"
                        className="form-control"
                        onChange={handleInputChange("city_id")}
                        value={data.city_id || (currentCity && currentCity.id)}
                      >
                        {city.map((c) => (
                          <option value={c.id} key={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="address">
                        Address
                        {data.deliveryType === "DELIVERY_STANDART" && (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </label>
                      <input
                        required={data.deliveryType === "DELIVERY_STANDART"}
                        type="text"
                        className="form-control"
                        value={data.address}
                        name="address"
                        onChange={handleInputChange("address")}
                        placeholder="Address here ...."
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <h5>Choose payment method</h5>
            <fieldset className="row py-3">
              {offline_payments.map((p) => (
                <div className="col-6 col-md-3">
                  <button
                    onClick={selectPayment}
                    className={`btn btn-block ${
                      p in data.preferred_payment
                        ? "btn-primary"
                        : "btn-outline-secondary"
                    }`}
                    value={p}
                    name="preferred_payment[]"
                  >
                    {p == "Cash" ? <FontAwesomeIcon icon={faWallet} size="3x" /> : <FontAwesomeIcon icon={faIdCard} size="3x" />}
                    <div className="h5">{p}</div>
                  </button>
                  {p in data.preferred_payment && (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id={p}
                      name=""
                      onChange={paymentValue}
                      placeholder="Введите сумму оплаты"
                    />
                  </div>)}
                </div>
              ))}
              {paymentIsSet && (
                payments.map((p) => (
                  <div className="col-6 col-md-3" key={p}>
                    <button
                      onClick={selectPayment}
                      className={`btn btn-block ${
                        p in data.preferred_payment
                          ? "btn-primary"
                          : "btn-outline-secondary"
                      }`}
                      value={p}
                      name="preferred_payment[]"
                    >
                      <FontAwesomeIcon icon={faGlobe} size="3x" />
                      <div className="h5">Online {p}</div>
                    </button>
                      {/* {p in data.preferred_payment && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id={p}
                          name=""
                          onChange={paymentValue}
                          placeholder="Введите сумму оплаты"
                        />
                      </div>)} */}
                  </div>
                ))
              )}
            </fieldset>
            <div className="text-right">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isLoading}
              >
                Register order
              </button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  payments: state.payments,
  offline_payments: state.offline_payments,
  city: state.city,
});

export default connect(mapStateToProps)(Checkout);
