import React, { useState } from "react";
import api from "../api";

const Delivery = ({ order }) => {
  const [disabled, toggle] = useState(false);
  if (
    ![
      "PAYMENT_RECEIVED",
      "APPROVED",
      "COMPLETED",
      "PROCESSING",
      "WAITING_FOR_COD_APPROVAL",
    ].includes(order.status)
  ) {
    return <></>;
  }
  const handler = (e) => {
    e.preventDefault();
    toggle(true);
    api.orders.delivery(order.id).then(() => {
      toggle(false);
    });
  };
  return (
    <button onClick={handler} disabled={disabled} className="btn btn-info">
      Sticker
    </button>
  );
};

export default Delivery;
