import React from "react";
import ProductFinder from "./ProductFinder";
import { clamp, isEmpty } from "lodash/fp";
import "./_cashier.scss";
import { format_money } from "../helpers";
import Checkout from "./Checkout";
import { selectCurrentShop } from "../reducers/shopReducer";
import { createOrder } from "../actions";
import { connect } from "react-redux";
import SingleProduct from "./SingleProduct";
import ProductTable from "./ProductTable";
import CustomerSelect from "./CustomerSelect";

class Cashier extends React.Component {
  state = {
    data: {},
    error: {},
    checkoutOpen: false,
    selectedProduct: null,
  };

  componentDidUpdate(prevProps) {
    const { shop: prevShop } = prevProps;
    const { shop } = this.props;
    if (shop && prevShop && shop.id !== prevShop.id) {
      this.resetData();
    }
  }

  resetData = () => {
    this.setState({
      data: {},
      error: {},
      checkoutOpen: false,
      selectedProduct: null,
    });
  };

  handleCustomerChange = (value) => {
    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        customer: value,
      },
    }));
  };

  handleAddProduct = (product) => {
    const { data } = this.state;
    if (data.products && data.products[product.id]) {
      return this.handleChangeProductQuantity(
        product.id,
        data.products[product.id].qty + 1
      );
    }
    this.setState((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          products: {
            ...prev.data.products,
            [product.id]: { ...product, qty: 1 },
          },
        },
      };
    });
  };

  handleChangeProductQuantity = (product_id, quantity) => {
    const {
      data: { products },
    } = this.state;
    if (products && products[product_id]) {
      this.setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          products: {
            ...prev.data.products,
            [product_id]: {
              ...prev.data.products[product_id],
              qty: clamp(1)(prev.data.products[product_id].quantity)(quantity),
            },
          },
        },
      }));
    }
  };

  handleProductQuantityInc = (p) => (e) => {
    e.preventDefault();
    this.handleChangeProductQuantity(p.id, p.qty + 1);
  };

  handleProductQuantityDec = (p) => (e) => {
    e.preventDefault();
    this.handleChangeProductQuantity(p.id, p.qty - 1);
  };

  handleCheckout = (data) => {
    const { checkout, shop } = this.props;
    const {
      data: { products, customer },
    } = this.state;
    return checkout({
      products: Object.values(products).map((p) => ({
        quantity: p.qty,
        id: p.id,
      })),
      ...data,
      shop_id: shop.id,
      customer_id: customer ? customer.value : null,
    })
      .then(() => {
        this.resetData();
      })
      .catch((e) => {
        if (e.response) {
          this.setState({ error: e.response.data });
        }
      });
  };

  handleRemoveProduct = (p_id) => (e) => {
    e.preventDefault();
    this.setState((prev) => {
      const { [p_id]: deleted, ...rest } = prev.data.products;
      return {
        ...prev,
        data: {
          ...prev.data,
          products: rest,
        },
      };
    });
  };

  handleOnProductRowClick = (p_id) => (e) => {
    e.preventDefault();
    this.setState({
      selectedProduct: p_id,
    });
  };

  handleProductClose = () => {
    this.setState({
      selectedProduct: null,
    });
  };

  render() {
    const {
      data: { products, customer },
      checkoutOpen,
      error,
      selectedProduct,
    } = this.state;
    const items = products ? Object.values(products) : [];
    const product = selectedProduct ? products[selectedProduct] : null;
    return (
      <div className="pt-3">
        <Checkout
          open={checkoutOpen}
          close={() => this.setState({ checkoutOpen: false })}
          submit={this.handleCheckout}
        />
        <h2>New Order</h2>
        {!isEmpty(error) && (
          <div className="alert alert-danger">{JSON.stringify(error)}</div>
        )}
        <div className="row cashier-row">
          <div className="col-md-8">
            <div className="cashier-card">
              <ProductFinder
                afterFound={this.handleAddProduct}
                customer_id={customer ? customer.value : null}
              />
            </div>
          </div>
          <div className="col-md-4  ">
            <div className="cashier-card">
              <dl className="row">
                <dt className="col-6">Subtotal</dt>
                <dd className="col-6">
                  {format_money(
                    items.reduce((cur, p) => cur + p.qty * p.final_price, 0)
                  )}
                </dd>
                <dt className="col-6">Product count</dt>
                <dd className="col-6">{items.length}</dd>
                <dt className="col-6">Customer</dt>
                <dd className="col-6">
                  <b>{customer ? customer.label : "-----"}</b>
                </dd>
              </dl>
              <CustomerSelect onChange={this.handleCustomerChange} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="cashier-card">
              <ProductTable
                items={items}
                inc={this.handleProductQuantityInc}
                dec={this.handleProductQuantityDec}
                remove={this.handleRemoveProduct}
                onProductClick={this.handleOnProductRowClick}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ checkoutOpen: true });
              }}
              disabled={!items.length}
            >
              Register order
            </button>
          </div>
        </div>
        {!!product && (
          <SingleProduct product={product} onClose={this.handleProductClose} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shop: selectCurrentShop(state),
});

export default connect(mapStateToProps, {
  checkout: createOrder,
})(Cashier);
