import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GuestRoute from "./components/GuestRoute";
import AuthRoute from "./components/AuthRoute";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import OrderPage from "./pages/OrderPage";
import OneClickPage from "./pages/OneClickPage";

function App() {
  return (
    <Router>
      <AuthRoute path="/" exact component={MainPage} />
      <GuestRoute path="/login" component={LoginPage} />
      <AuthRoute path="/orders" component={OrderPage} />
      <AuthRoute path="/one-click" component={OneClickPage} />
    </Router>
  );
}

export default App;
