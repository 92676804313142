import { SHOP_RETRIEVED, SHOP_SWITCHED } from "../actions";

export default (state = { items: [], current: null }, { type, payload }) => {
  switch (type) {
    case SHOP_RETRIEVED:
      let current = state.current;
      if (!payload.filter((sh) => sh.id === current).length) {
        current = payload[0] ? payload[0].id : null;
      }
      return {
        ...state,
        items: payload,
        current: current,
      };
    case SHOP_SWITCHED:
      return {
        ...state,
        current: payload.id,
      };

    default:
      return state;
  }
};

export const selectCurrentShop = (state) => {
  const {
    shop: { items, current },
  } = state;
  let v = items.filter((s) => s.id == current);
  return v[0] || null;
};
