import { CITY_RETRIEVED } from "../actions";

export default (state = [], { type, payload }) => {
  switch (type) {
    case CITY_RETRIEVED:
      return payload.data || [];

    default:
      return state;
  }
};
