import React, { Component, useEffect, useState } from "react";
import api from "../api";

import AsyncSelect from "react-select/async";
import Axios from "axios";

const CustomerSelect = ({ onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    api.shop.customers().then((data) => setOptions(data));
  }, []);

  const filterCustomers = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterCustomers(inputValue));
      }, 500);
    });
  return (
    <AsyncSelect
      cacheOptions
      isClearable
      defaultOptions
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default CustomerSelect;
