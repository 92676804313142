import { clamp, range, orderBy } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { getApplications } from "../actions";
import ApplicationRow from "../components/ApplicationRow";
import { selectCurrentShop } from "../reducers/shopReducer";
import OneClickCheckout from "./OneClickCheckout";

const OneClickPage = ({ shop, meta, applications, load }) => {
  const [{ page, current }, changeState] = useState({ page: 0, current: null });
  const [loading, toggleLoading] = useState(true);

  const changePage = (p) => changeState({ page: p, current });
  const setCurrent = (order_id) => changeState({ page, current: order_id });

  useEffect(() => {
    if (shop) {
      toggleLoading(true);
      load(shop.id, page + 1).then(() => toggleLoading(false));
    }
  }, [shop, load, page]);

  const paginate = (p) => (e) => {
    e.preventDefault();
    changePage(p);
  };

  const pages = meta.last_page ? range(1)(meta.last_page + 1) : [];

  const starting = clamp(0)(page - 10)(pages.length);
  const ending = clamp(starting)(starting + 20)(pages.length);
  console.log(applications);
  return (
    <main className="">
      <div className="container-fluid pt-3">
        <h2>One click Orders</h2>

        {pages.length > 1 && (
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link"
                  href={`/orders?page=0`}
                  aria-label="Previous"
                  onClick={paginate(0)}
                  disabled={page === 0}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {range(starting)(ending).map((i) => (
                <li
                  className={`page-item${i === page ? " active" : ""}`}
                  key={i}
                >
                  <a
                    className="page-link"
                    href={`/orders?page=${i}`}
                    onClick={paginate(i)}
                  >
                    {i + 1}
                  </a>
                </li>
              ))}
              <li class="page-item">
                <a
                  class="page-link"
                  href={`/orders?page=${pages.length - 1}`}
                  aria-label="Next"
                  onClick={paginate(pages.length - 1)}
                  disabled={page === pages.length - 1}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        )}
        <div className="cashier-card">
          {loading ? (
            <div class="text-center py-5">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <table className="table table-striped table-sm table-borderless">
              <thead>
                <tr>
                  <td>Index</td>
                  <td>ID</td>
                  <td style={{ lineHeight: 1 }}>Name</td>
                  <td>Phone</td>
                  <td>Cashier</td>
                  <td>Total</td>
                  <td>Processed?</td>
                  <td>Date</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {!!applications &&
                  orderBy("id")("desc")(applications).map((o, index) => (
                    <ApplicationRow
                      onShow={setCurrent}
                      key={o.id}
                      index={meta.from + index}
                      application={o}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <Route
          path="/one-click/:application_id"
          exact
          component={OneClickCheckout}
        />
      </div>
    </main>
  );
};

export default connect(
  (state) => {
    const shop = selectCurrentShop(state);
    return {
      shop,
      applications: Object.values(state.oneClick.items),
      meta: state.oneClick.meta,
    };
  },
  { load: getApplications }
)(OneClickPage);
