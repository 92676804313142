import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectUser } from "../reducers/authReducer";
import { selectCurrentShop } from "../reducers/shopReducer";
import { logout } from "../actions";
import "./topnav.scss";

const Topnav = ({ user, shop, logout }) => {
  return (
    <div className="topnav">
      <div className="topnav__left">
        <NavLink to="/" exact>
          Register order
        </NavLink>
        <NavLink to="/one-click">One Clicks</NavLink>
        <NavLink to="/orders">Orders</NavLink>
      </div>
      <div className="topnav__right">
        <div className="topnav__user">User: {user && user.name}</div>
        <div className="topnav__store">Store: {shop && shop.name}</div>
        <button
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
          className="topnav__logout"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  shop: selectCurrentShop(state),
});

export default connect(mapStateToProps, { logout })(Topnav);
